import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import HeroDigitalMarketing from "../../components/ServiceSinglePage/Hero/DigitalMarketing/HeroDigitalMarketing"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import HeroUIUX from "../../components/ServiceSinglePage/Hero/UIUX/HeroUIUX"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"

function UIUXPage({ location }) {
    const heroTextTitle = "Professional Web Design Services"
    const heroTextSmallText =
        "Enhance your brand with our custom graphic designs that combine striking visuals and smooth functionality, ensuring captivating digital experiences that engage your audience and boost conversions."
    const ctaTitle = "Tailored Web Design Services That Suit Your Needs!"
    const heroData = [
        "At Saigon Digital, we excel at creating websites that engage audiences and drive conversions.",
        "We know that outstanding UI/UX design is vital for conveying your brand's story effectively across all digital platforms. By combining creative flair with technical expertise, we craft digital experiences that both captivate and simplify, ensuring your audience has a seamless and memorable interaction, whether on a mobile, tablet, or desktop.",
        "Our team is dedicated to bringing your vision to life by designing user-friendly, responsive, and visually striking websites that leave a lasting impact and boost engagement. No matter if you are a small business, a startup, or an established company, our web design services are tailored to suit all needs. ",
        "Seize the chance to enhance your online presence—get a FREE quote today and let us turn your web aspirations into reality, building strong and meaningful connections between your brand and its audience."
    ]

    const strategyIcons = [
        {
            name: "checklist",
            title: "Case Analysis",
            bulletPoints: [
                "Functional requirment collection",
                "Business objectives assessment",
                "Tech stack choice",
                "Competitive research",
                "Target audience groups discovery"
            ]
        },
        {
            name: "search",
            title: "User Research",
            bulletPoints: [
                "Potential audience interviewing",
                "User expectation evalution",
                "Behavioral & attitudinal analysis",
                "User persona concepts creation",
                "Brainstorming, solution strategising"
            ]
        },
        {
            name: "dart",
            title: "Ideation",
            bulletPoints: [
                "Information architecture",
                "UX conceptualisation",
                "Storyboard creation",
                "Userflow diagramming",
                "Navigation structuring or site mapping"
            ]
        }
    ]

    const designIcons = [
        {
            name: "checklist",
            title: "Product design",
            bulletPoints: [
                "User-centered UX design",
                "Visual / UI Design",
                "UI motion design",
                "UI library, design system"
            ]
        },
        {
            name: "dart",
            title: "Accessibility",
            bulletPoints: [
                "UI/UX content",
                "UX copywriting",
                "Illustrations",
                "Graphics"
            ]
        }
    ]

    const consultIcons = [
        {
            name: "checklist",
            title: "Comprehesive UI/UX audit",
            bulletPoints: [
                "Accessibility checks",
                "Scalability analysis",
                "Localisation",
                "UI/UX testing",
                "Internal testing"
            ]
        },
        {
            name: "search",
            title: "Quantitative research",
            bulletPoints: [
                "Google analytics",
                "A/B testing",
                "Live user testing",
                "Solution beta launch"
            ]
        },
        {
            name: "dart",
            title: "UIUX strategy improvement",
            bulletPoints: [
                "User behaviour analysis",
                "Usability report",
                "Post-launch analytics report",
                "Intergrations & updates"
            ]
        }
    ]

    const cta2 = {
        title: "Your Brand Deserves A Digital Masterpiece. Let's Make It Happen!",
        ctaText: "Book a call"
    }

    const context = {
        pageName: "Service | Professional Web Design Services",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroUIUX
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <div className="[&_.service-icon]:pb-0 lg:[&_.service-icon]:pt-40 [&_.wave-bottom]:hidden">
                <ServiceIconBlock
                    title={"Digital Strategy"}
                    icons={strategyIcons}
                />
            </div>
            <div className="[&_.service-icon]:py-[120px] [&_.wave-bottom]:hidden [&_.wave-top]:hidden">
                <ServiceIconBlock
                    title={"Digital Experience"}
                    icons={designIcons}
                />
            </div>
            <div className="[&_.service-icon]:pt-0 lg:[&_.service-icon]:pb-40 [&_.wave-top]:hidden">
                <ServiceIconBlock
                    title={"Digital Consulting"}
                    icons={consultIcons}
                />
            </div>
            <div className="mt-36">
                <ServiceCTA {...cta2} />
            </div>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.webDesignFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default UIUXPage
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: faqs.webDesignFaqs.map(faq => ({
                        "@type": "Question",
                        name: faq?.question,
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: faq?.answer[0]
                        }
                    }))
                })}
            </script>
        </Helmet>
        <SEO
            title="Professional Web Design Services | Saigon Digital"
            description="Explore Saigon Digital's UI/UX design services for seamless, user-centric digital experiences that drive engagement."
        />
    </>
)
